const config = {
  authentication: {
    providers: {
      email: {
        enable: true,
      },
      google: {
        enable: false,
        environments: {
          development: {
            authUrl: '/users/auth/google_oauth2'
          }
        }
      }
    },
  }, 
  sort_records: {
    limit_size_default: 200000
  }
};

export default config;
